import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 200px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></td>
            <td style="width: 130px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 200px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></td>
            <td style="width: 200px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></td>
        </tr>
        `
const SinglePage = (lesson) => `
    <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">    
        <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
            <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
        </div>
        <div style="margin-top: 24px; text-align: center"><strong style="font-size: 18px">Giorno nr. {row_number} - {nome_corso} {id}</strong></div>
        <p>Data: {data}</p>
        <table style="width: 100%; border: 1px solid black; border-collapse:collapse; font-size: 10px; font-weight: normal; text-align:left">
            <thead style="border: 1px solid black; border-collapse:collapse">
                <tr style="border: 1px solid black; border-collapse:collapse">
                    <th style="width: 100px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></th>
                    <th style="width: 100px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">ORARIO ENTRATA</th>                    
                    <th style="width: 130px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">COGNOME</th>
                    <th style="width: 130px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">NOME</th>
                    <th style="width: 100px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">ORARIO USCITA</th>
                    <th style="width: 200px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">Firma partecipante</th>
                </tr>
            </thead>
            <tbody style="border: 1px solid black; border-collapse:collapse">
               {rows}
            </tbody>
        </table>
        <div style="margin-top: 200px; display: ruby-text;">
            <div style="width: 200px; font-size: 11px">
                <strong>FIRMA DEL DIRETTORE DEL CORSO</strong><br/>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{direttore}</i>
            </div>
        </div>
            <p style="margin: 0 auto;padding-top: 80px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>

            </div>
        </div>
        <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">    
            <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
                <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
            </div>
            <h3>{nome_corso} ({name_eng})</h3>
            <h3>{id}</h3>
            <strong>{row_number}° Giorno<strong>
            <strong>Data - {data} </strong>

            {single_lessons}
            
            <p style="margin: 0 auto;padding-top: 10px; font-weight: normal; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>

            </div>
        </div>`
const SingleLesson = (lesson, idx) => `<div style="margin-top: 50px;">
                <strong>${idx}° lezione</strong>
                <div style="max-width: 400px;">
                    <table style="width: 100%; border-collapse: collapse; font-weight: normal;">
                            <tr>
                                <td style="text-align: left; padding: 5px;">
                                    <p style="margin: 0;">Inizio</p>
                                    <span style="font-weight: bold;">${lesson.hour_ranges.split('-')[0]}</span>
                                </td>
                                <td style="text-align: left; padding: 5px;">
                                    <p style="margin: 0;">Fine</p>
                                    <span style="font-weight: bold;">${lesson.hour_ranges.split('-')[1]}</span>
                                </td>
                                <td style="text-align: left; padding: 5px;">
                                    <p style="margin: 0;">Totale ore</p>
                                    <span style="font-weight: bold;">{total_hour}</span>
                                </td>
                            </tr>
                        </table>
                </div>
                <table style="margin-top: 24px">
                    <tr>
                        <td style="font-weight: normal; width: 380px;">Insegnante: <strong>${lesson.teacher}</strong> <br/>
                         tipologia: <strong>${lesson.pratica ? 'Pratica' : 'Teorica'}</strong>
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td><p>firma</p></td>
                                    <td>
                                <div style="width: 250px; height: 18px; border-bottom:1px solid #000"></div></td>
                                </tr>
                                
                            </table>
                        </td>
                    </tr>
                </table>
                <div style="margin-top: 48px"></div>
                <strong style="padding-top: 48px;">Descrizione lezione:</strong><br/>
                <p style="font-weight: normal">${lesson.description}</p>
            </div>`;
const SingleExam = (date_exam) => `
<div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">    
<div style="width: 100%; display: block; margin: 0 auto; text-align:center">
    <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
</div>
<div style="margin-top: 24px; text-align: center"><strong style="font-size: 18px">Giorno dell'esame - {nome_corso} {id}</strong></div>
<p>Data: ${date_exam} (esame)</p>
<table style="width: 100%; border: 1px solid black; border-collapse:collapse; font-size: 10px; font-weight: normal; text-align:left">
    <thead style="border: 1px solid black; border-collapse:collapse">
        <tr style="border: 1px solid black; border-collapse:collapse">
            <th style="width: 100px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></th>
            <th style="width: 100px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">ORARIO ENTRATA</th>                    
            <th style="width: 130px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">COGNOME</th>
            <th style="width: 130px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">NOME</th>
            <th style="width: 100px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">ORARIO USCITA</th>
            <th style="width: 200px;padding-left: 4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">Firma partecipante</th>
        </tr>
    </thead>
    <tbody style="border: 1px solid black; border-collapse:collapse">
        {rows}
    </tbody>
</table>
<div style="margin-top: 200px; display: ruby-text;">
    <div style="width: 200px; font-size: 11px">
        <strong>FIRMA DEL DIRETTORE DEL CORSO</strong><br/>
        <div style="padding-top: 32px; border-bottom:1px solid"></div>
        <i style="font-size: 8px; margin-top:5px; display:block;">{direttore}</i>
    </div>
</div>
    <p style="margin: 0 auto;padding-top: 80px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>

    </div>
</div>
`
const Libretto = `<html>
                <body style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; border: 5px solid #99ff; padding: 12px 24px; width: 800px">
                    <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;" class="page html2pdf__page-break">
                        <div style="height: 1035px; display: block; border: 5px solid {settings.color};padding-left: 25px; padding-right: 25px">
                            
                            <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>

                            <h2>Registro delle presenze</h2>

                            <h1>{id}</h1>
                            <h3>({name_eng})</h3>
                            <br/><br /><br/><br />
                            <strong>Inizio del corso:</strong> {from}</strong> <br/><br />
                            <strong>Fine del corso:</strong> {to}</strong> <br/> <br/>
                            <strong>Durata:</strong> {length} ore</strong><br/><br />
                            <strong>Esami finali: </strong> {exam_day} ore {exam_hour}</strong>
                        </div>
                    <p style="margin: 0 auto; padding-top: 10px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
                    </div>

                    {pages}

                    {exam_page}
    </body>
</html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    console.log(payload);
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'verbale_esame.pdf',
        html2canvas: { useCORS: true, allowTaint: true },
        pagebreak: {mode: 'legacy'}
    })
    .from(Libretto
        .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
        .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
        .replaceAll('{school_name}', `${payload.school.name}`)
        .replaceAll('{legal_address}', payload.school.sede_legale)
        .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
        .replaceAll('{website}', payload.school.url)
        .replaceAll('{piva}', payload.school.piva)
        .replaceAll('{exam_day}', `${moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').format('dddd, DD/MM/YYYY')}`)
        .replaceAll('{exam_hour}', `${payload.course.dataValues.ora_esame}`)
        .replaceAll('{length}', payload.lessons.reduce((acc, curr) => acc + (moment(curr.hour_ranges.split('-')[1], 'HH:mm').diff(moment(curr.hour_ranges.split('-')[0], 'HH:mm'), 'hours')), 0))
        .replaceAll('{from}', moment(payload.course.dataValues.from).format('DD/MM/YYYY'))
        .replaceAll('{to}', moment(payload.course.dataValues.to).format('DD/MM/YYYY'))
        .replaceAll('{name_eng}', payload.generalCourse.print_name_it)
        .replaceAll('{pages}', Object.keys(payload.groupedByDate).map((day, idx) => 
            SinglePage(day).replaceAll('{docente}', payload.teacher).replaceAll('{row_number}', idx + 1)
            .replaceAll('{nome_corso}', payload.generalCourse['nome corso'])
            .replaceAll('{single_lessons}', payload.groupedByDate[day].map((lesson, idx) => SingleLesson(lesson, idx + 1)
                .replaceAll('{total_hour}', lesson.hours / 10)
            ).join(''))
            .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
            .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{data}', moment(day).format('DD/MM/YYYY'))
            .replaceAll('{rows}', payload.partecipant.map((partecipant, idxP) => SingleRow(partecipant).replaceAll('{id_matricola}', `${(idxP+1).toString().padStart(3, '0')}/${payload.id ? payload.id : `2024/${payload.nome_corso}/1`}`).replaceAll('{first_name}', `${partecipant.first_name}`)
                .replaceAll('{last_name}', `${partecipant.last_name}`)
             ).join(''))
            .replaceAll('{school_name}', `${payload.school.name}`)
            .replaceAll('{legal_address}', payload.school.sede_legale)
            .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
            .replaceAll('{website}', payload.school.url)
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{piva}', payload.school.piva)).join(''))
            .replaceAll('{name_eng}', payload.generalCourse.print_name_it)
        .replaceAll('{exam_page}', SingleExam(moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').format('dddd, DD/MM/YYYY')).replaceAll('{docente}', payload.teacher)
        .replaceAll('{nome_corso}', payload.generalCourse['nome corso'])
        .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
        .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
        .replaceAll('{rows}', payload.partecipant.map((partecipant, idxP) => SingleRow(partecipant).replaceAll('{id_matricola}', `${(idxP+1).toString().padStart(3, '0')}/${payload.id ? payload.id : `2024/${payload.nome_corso}/1`}`).replaceAll('{first_name}', `${partecipant.first_name}`)
            .replaceAll('{last_name}', `${partecipant.last_name}`)
         ).join(''))
        .replaceAll('{school_name}', `${payload.school.name}`)
        .replaceAll('{legal_address}', payload.school.sede_legale)
        .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
        .replaceAll('{website}', payload.school.url)
        .replaceAll('{direttore}', payload.director))
    ).toPdf().get('pdf').then(pdf => {
        window.open(pdf.output('bloburl'), '_blank');
    })
}
export { Libretto, savePdf }