// import ListLeads from "../views/pages/leads/ListLeads";
// import AddLead from "../views/pages/leads/AddLead";

import ListUsers from "../views/pages/users/ListUsers";

import ListCustomers from "../views/pages/customers/ListCustomers";
import Profile from "../views/pages/profile/Profile";
import ListLeads from "../views/pages/leads/ListLeads";
import GetLead from "../views/pages/leads/GetLead";
import CreateForm from "../views/pages/customers/CreateForm";
import ListContract from "../views/pages/contracts/ListContract";
import Home from '../views/pages/index/Index'
import ListOffers from "../views/pages/offers/ListOffers";
import ListCourses from "../views/pages/courses/ListCourses";
import ListTeachers from "../views/pages/teachers/ListTeachers";
import ListRequests from "../views/pages/requests/ListRequests";
import ListPromo from "../views/pages/promo/ListPromo";
import ListPrenotazioni from "../views/pages/prenotazioni/ListPrenotazioni";
import Centro from "../views/pages/centro-formazione/Centro";
import CourseDetail from "../views/pages/courses/CourseDetail";
import ListReviews from "../views/pages/recensioni/ListReviews";
import Accreditamento from "../views/pages/courses/Accreditamento";
import Settings from "../views/pages/centro-formazione/Settings";
import ListAccreditamentoDocente from "../views/pages/accreditamento_docente/ListAccreditamento";
import ListAccreditamentoDirettore from "../views/pages/accreditamento_vicedirettore/ListAccreditamento";
import ListAccreditamentoAule from "../views/pages/accreditamento_aule/ListAccreditamento";
import ListSedi from "../views/pages/sedi/ListSedi";

const ChildRouter = [
    {
        path: '/',
        element: <Home />,
        title: 'Index'
    },
    {
        path: '/dashboard',
        element: <Home />,
        title: 'Index'
    },
    {
        path: '/corsi',
        element: <ListCourses />,
        title: 'Lista corsi',
        group: 'Corsi'
    },
    {
        path: '/docenti',
        element: <ListTeachers />,
        title: 'Lista docenti',
        group: 'Corsi'
    },
    {
        path: '/docenti/accreditamento',
        element: <ListAccreditamentoDocente />,
        title: 'Lista docenti',
        group: 'Corsi'
    },
    {
        path: '/sedi',
        element: <ListSedi />,
        title: 'Lista Sedi',
        group: 'Corsi' 
    },
    {
        path: '/direttori/accreditamento',
        element: <ListAccreditamentoDirettore />,
        title: 'Lista docenti',
        group: 'Corsi'
    },
    {
        path: '/aule/accreditamento',
        element: <ListAccreditamentoAule />,
        title: 'Lista docenti',
        group: 'Corsi'
    },
    {
        path: '/accreditamento',
        element: <Accreditamento />,
        title: 'Accreditamento',
        group: "Corsi"
    },
    {
        path: '/richieste',
        element: <ListRequests />,
        title: 'Lista richieste iscrizioni',
        group: 'Corsi'
    },
    {
        path: '/recensioni',
        element: <ListReviews />,
        title: 'Lista recensioni',
        group: 'Corsi'
    },
    {
        path: '/career-path',
        element: <ListCustomers />,
        title: 'Lista Committenti',
        group: 'Committenti'
    },
    {
        path: '/committenti/form/crea',
        element: <CreateForm />,
        title: 'Aggiungi Form',
        group: 'Committenti'
    },
    {
        path: '/leads',
        element: <ListLeads />,
        title: 'Lista Leads',
        group: 'Leads'
    },
    {
        path: '/promozioni',
        element: <ListPromo />,
        title: 'Lista Promo',
        group: 'Leads'
    },
    {
        path: '/prenotazioni',
        element: <ListPrenotazioni />,
        title: 'Lista prenotazioni',
        group: 'Leads'
    },
    {
        path: '/centro di formazione',
        element: <Centro />,
        title: 'Lista prenotazioni',
        group: 'Leads'
    },
    {
        path: '/leads/:id',
        element: <GetLead />,
        title: 'Dettaglio Lead',
        group: 'Leads'
    },
    {
        path: '/contratti',
        element: <ListContract />
    },
    {
        path: '/contratti/:id',
        element: <GetLead />,
        title: 'Dettaglio Lead',
        group: 'Leads'
    },
    {
        path: '/profilo',
        element: <Profile />,
        title: "Modifica profilo",
    },
    {
        path: '/centri formazione',
        element: <ListUsers />,
        title: "Lista utenti"
    },
    {
        path: '/offerte',
        element: <ListOffers />,
        title: "Lista Offerte"
    },
    {
        path: '/impostazioni',
        element: <Settings />,
        title: "Impostazioni"
    },
    {
        path: '/corso/:id',
        element: <CourseDetail />,
        title: "Corso"
    }
];

export { ChildRouter };