import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, styled, Modal, TextField } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState, addCourse } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it';
import { useParams } from 'react-router';
import { savePdf as saveCertificato } from "../courses/pdfs/certificato";
import { savePdf as saveVerbale } from "../courses/pdfs/verbale_esame";
import { savePdf as saveRegistroEsame } from "../courses/pdfs/registro_presenze_esame";
import { savePdf as saveRegistro } from "../courses/pdfs/registro_presenze_totale";
import { savePdf as saveElenco } from "../courses/pdfs/elenco_partecipanti";
import { savePdf as saveLettera } from "../courses/pdfs/lettera_esami";
import { savePdf as saveLibretto } from "../courses/pdfs/libretto";
import { savePdf as saveProgrammazione } from "../courses/pdfs/programmazione_corso";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthHelper } from "../../controllers/AuthHelper";
import DataTable from "../../components/Table";
import { PanoramaSharp } from "@mui/icons-material";
import { CreateTicket } from "../../../CreateTicket";
moment.locale('it');
const localizer = momentLocalizer(moment)
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header'},
    { field: 'first_name', headerName: 'Nome', width: 200, headerClassName: 'data-grid-header' },
    { field: 'last_name', headerName: 'Cognome', width: 200, headerClassName: 'data-grid-header' },
    { field: 'phone_number', headerName: 'Numero di Telefono', width: 200, headerClassName: 'data-grid-header' },
    { field: 'birth_province', headerName: 'Provincia di Nascita', width: 200, headerClassName: 'data-grid-header' },
    { field: 'birthplace', headerName: 'Città di Nascita', width: 200, headerClassName: 'data-grid-header' },
    { field: 'city_residence', headerName: 'Città di Residenza', width: 200, headerClassName: 'data-grid-header' },
    { field: 'birthday', headerName: 'Data di Nascita', width: 200, headerClassName: 'data-grid-header' },
];

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: '#888',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const ModalCapitaneria = (props) => {
    const handleOpen = () => props.setOpen(true);
    const handleClose = () => props.setOpen(false);
  
    return (
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Sei sicuro di voler inviare la comunicazione alla capitaneria?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Verrà inviata la comunicazione precompilata con i seguenti allegati:
              <ul>
                <li>
                  <div onClick={async (e) => {
                    e.preventDefault();
                    try{
                      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${props.course}`);
                      const payload = await res.json();
                      await saveLettera(payload);
                    }catch(ex){
                      alert("Setup corso non completo");
                    }
                   
                  }}>
                    Lettera conferma sessione esami
                  </div>
                </li>
                <li>
                <div onClick={async (e) => {
                    e.preventDefault();
                    try{
                      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${props.course}`);
                      const payload = await res.json();
                      await saveElenco(payload);
                    }catch(ex){
                      alert("Setup corso non completo");
                    }
                   
                  }}>
                    Elenco partecipanti
                  </div>
                </li>
                <li>
                <div onClick={async (e) => {
                    e.preventDefault();
                    try{
                      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/print/${props.course}`);
                      const payload = await res.json();
                      const partecipants = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/partecipants/${props.course}`)).json();
                      const lessons = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/lessons/${props.course}`)).json();
                      await saveProgrammazione({...payload, lessons: lessons, partecipants});
                    }catch(ex){
                      alert("Setup corso non completo");
                    }
                   
                  }}>
                    Programmazione corso
                  </div>
                </li>
              </ul>
            </Typography>
            <TextField fullWidth minRows={4} multiline placeholder="Messaggio aggiuntivo a capitaneria" />
            <div style={{display: 'flex'}}>
              <Button color="error" onClick={handleClose}>Annulla</Button>
              <Button onClick={() => alert("Bisogna configurare l'account PEC per il Centro di formazione.")}>Invia</Button>
            </div>
          </Box>
        </Modal>
    );
  }
const ListCustomers = () => {
    const [dashboard, setDashboard] = useState({
      starting: [],
      subscription: [],
      ended: []
    })
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const [ticketOpen, setTicketOpen] = useState(false);

    const getDashboard = async() => {
      const user = await AuthHelper.getUserFromIdToken();

      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/schools/dashboard/${user.Username}`);
      const json = await res.json();
      
      setDashboard({
        starting: [...json.coursesStarting.sort((a,b) => moment(a['from']).diff(moment(b['from'])))],
        subscription: [...json.partecipants],
        ended: [...json.previousCourses]
      })
    }
    const groupedCourses = (array) => array.reduce((acc, course) => {
      const courseName = course["nome corso"];
      if (!acc[courseName]) {
        acc[courseName] = [];
      }
      acc[courseName].push(course);
      return acc;
    }, {});
    const groupedSubscription = (array) => array.reduce((acc, course) => {
      const courseName = course["nome corso"];
      if (!acc[courseName]) {
        acc[courseName] = [];
      }
      acc[courseName].push(course);
      return acc;
    }, {});

    const [marittimi, setMarittimi] = useState([]);
    const [open, setOpen] = useState(false);
    const [course, setCourse] = useState("");

    const getMarittimi = async() => {
      const user = await AuthHelper.getUserFromIdToken();

      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/schools/partecipants/${user.Username}`)
      setMarittimi([...(await res.json())[0]]);
    }
    useEffect(() => {
      getDashboard();
      getMarittimi();
    }, [])
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '85vw', marginTop: '3rem', textAlign: 'left'}}>
        <ModalCapitaneria open={open} setOpen={setOpen} course={course}></ModalCapitaneria>
        <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12, marginBottom: 3}} onClick={() => window.location.href="/dashboard/accreditamento"}>Lista accreditamento corsi</Button>
        <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12, marginBottom: 3}} onClick={() => setTicketOpen(true)}>Invia ticket</Button>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <AntTab label="Operations" {...a11yProps(0)} />
          <AntTab label="Data" {...a11yProps(1)} />
          <AntTab label='Archivio "Corsi Conclusi"' {...a11yProps(2)} />
          <AntTab label='Database discenti' {...a11yProps(3)} />
        </Tabs>
      <CreateTicket modalOpen={ticketOpen} setModalOpen={setTicketOpen} />
      <CustomTabPanel value={value} index={0}>
        <div style={{display: 'flex', gap: 12, justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', textAlign: 'left'}}>
                <h3>Corsi in partenza</h3>
                {dashboard.starting?.length > 0 && dashboard.starting?.[0]?.sort((a,b) => moment(a.from).diff(moment(b.from)))?.map(course => (
                  <div style={{display: 'flex', gap: 12, alignItems: 'center'}}  onClick={() => window.location.href=`/dashboard/corso/${course['id']}?nome_corso=${encodeURIComponent(course['nome corso'])}&id=${course['course_id']}&start=${course['from']}&end=${course['to']}`}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>{course['numero_partecipanti']}</div>
                    <h4>{course['nome corso']} ({moment(course['from']).format('DD/MM/YYYY')} - {moment(course['to']).format('DD/MM/YYYY')})</h4>
                  </div>
                ))}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <h3>Richieste Iscritti</h3>
                {dashboard.starting?.length > 0 && Object.keys(groupedCourses(dashboard.subscription[0])).map(key => (
                  <div style={{display: 'flex', gap: 12, alignItems: 'center'}} onClick={() => window.location.href="/dashboard/prenotazioni"}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>{groupedCourses(dashboard.subscription[0])[key].length}</div>
                    <h4>{key}</h4>
                  </div>
                ))}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <h3>Comunicazioni Capitaneria</h3>
                  {dashboard.starting?.length > 0 && dashboard.starting?.[0]?.filter(x => moment(x['from']).diff(moment(), 'days') <= 7).sort((a,b) => moment(a.from).diff(moment(b.from)))?.map(course => (
                    <div style={{display: 'flex', gap: 12, alignItems: 'center'}}  onClick={() => {setOpen(true); setCourse(course['id'])}}>
                      <div style={{padding: '6px 12px', background: '#D22B2B', color: '#fff', borderRadius: 6}}>3</div>
                      {moment(course['from']).diff(moment(), 'hours') > 24 ? <div style={{padding: '6px 12px', background: moment(course['from']).diff(moment(), 'hours') > 72 ? '#FFFF00' : '#D22B2B', color: moment(course['from']).diff(moment(), 'hours') > 72 ? '#000' : '#fff', borderRadius: 6}}>P</div> : <div style={{padding: '6px 12px', background: '#D22B2B', color: '#fff', borderRadius: 6}}><strong>D</strong></div>}
                      
                      <h4>{course['nome corso']} ({moment(course['from']).format('DD/MM/YYYY')} - {moment(course['to']).format('DD/MM/YYYY')})</h4>
                    </div>
                  ))}
            </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div style={{display: 'flex', gap: 12, justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', textAlign: 'left'}}>
                <h3>Il tuo Score</h3>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>0%</div>
                    <h4>Traning Center Page Score</h4>
                </div>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>0%</div>
                    <h4>Review Score Marittimi</h4>
                </div>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>0%</div>
                    <h4>Review Score Docenti</h4>
                </div>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>0%</div>
                    <h4>Review Search Score 30 Days</h4>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <h3>% Presenze Marittimi</h3>
                {dashboard.starting?.length > 0 && dashboard.starting?.[0].map(course => (
                  <div style={{display: 'flex', gap: 12, alignItems: 'center'}}  onClick={() => window.location.href=`/dashboard/corso/${course['id']}?nome_corso=${encodeURIComponent(course['nome corso'])}&id=${course['course_id']}&start=${course['from']}&end=${course['to']}`}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>{Math.round(course['numero_partecipanti'] / 20 * 100)}%</div>
                    <h4>{course['nome corso']}</h4>
                  </div>
                ))}
                
            </div>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <h3>Richieste Capitaneria</h3>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    Nessuna richiesta capitaneria.
                </div>
                
            </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div style={{display: 'flex', gap: 12, justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', textAlign: 'left'}}>
                <h3>Corsi Conclusi</h3>
                {dashboard.ended?.length > 0 && dashboard.ended?.[0].map(course => (
                  <div style={{display: 'flex', gap: 12, alignItems: 'center'}}  onClick={() => window.location.href=`/dashboard/corso/${course['id']}?nome_corso=${encodeURIComponent(course['nome corso'])}&id=${course['course_id']}&start=${course['from']}&end=${course['to']}`}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>{course['numero_partecipanti']}</div>
                    <h4>{course['nome corso']} ({moment(course['from']).format('DD/MM/YYYY')} - {moment(course['to']).format('DD/MM/YYYY')})</h4>
                  </div>
                ))}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <h3>Documenti Corso</h3>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <div style={{padding: '6px 12px', background: 'rgb(191, 163, 73)', color: '#fff', borderRadius: 6}}>6</div>
                    <h4>Corso antincendio base</h4>
                </div>
                
            </div>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <h3>Documenti per capitaneria</h3>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    Nessun documento per capitaneria.
                </div>
                
            </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <div style={{display: 'flex', gap: 12, justifyContent: 'space-between'}}>
          <DataTable data={marittimi.filter(x => x.id)} columns={columns}/>
        </div>
      </CustomTabPanel>
    </Container>);
}

export default ListCustomers;