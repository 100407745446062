import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, Rating } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";

import DataTable from "../../components/Table";
import moment from "moment";
import { AuthHelper } from "../../controllers/AuthHelper";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const ListSedi = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [value, setValue] = useState(0);
    const [recensioni, setReviews] = useState([]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const findReviews = async() => {
      const user = await AuthHelper.getUserFromIdToken();

      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/sedi/${user.Username}`);
      const json = await res.json();
      setReviews([...json]);
    }
    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header'},
        { field: 'nome_sede', headerName: 'Nome Sede', width: 200, headerClassName: 'data-grid-header' },
        { field: 'indirizzo_sede', headerName: 'Indirizzo Sede', width: 200, headerClassName: 'data-grid-header' },
        { field: 'createdAt', headerName: 'Data invio', width: 200, headerClassName: 'data-grid-header',valueGetter: (value) => moment(value).format('DD/MM/YYYY') }
    ];

    useEffect(() => {
        findReviews();
        store.dispatch(clear())
        builder('promo.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('sedi.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Crea Domanda", data.row);

    }

    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Lista sedi" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading && <ListSkeleton />}
        {/* {!loading && <DataTable data={certificates} columns={columns} handleClick={handleClick} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>} */}
        {!loading && <DataTable data={recensioni} columns={columns} actions={[
        {type: 'select', name: 'Azioni'}
        ]}/>}
            <FloatingActionButtons onClick={() => handleClick([], "Crea Domanda")}/>

      </CustomTabPanel>
   
    
    </Container>);
}

export default ListSedi;