import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const singleCourseTemplate = `<div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 32px; text-align: center">
<table border=""1 style="margin: 0 auto; border: 1px solid #000; width: 100%;">
    <tr style="border-bottom: 1px solid #000">
      <td ><strong>Lezione nr.{no}</strong></td>
      <td colspan="3"><strong>Corso {course} ore {hour_range}</strong></td>
    </tr>
    <tr>
        <td colspan="4" style="font-size: 14px; text-align: left;">
            Programma: {description}
        </td>
    </tr>
    <tr>
        <td style="text-align: right">Didattica:</td>
        <td>{theory}</td>
        <td style="text-align: right">Totale Ore:</td>
        <td>{hours}</td>
    </tr>
  </table>
</div>`


const Libretto = `<html>
    <body style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; padding: 12px 24px">
        <div style="padding-left: 2rem; padding-right: 2rem" class="page html2pdf__page-break">
        <div style="width: 100%; display: flex; justify-content: center; margin: 0 auto; text-align:center">
            <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
        
        </div>
        <div style="margin-top: 24px; text-align: center"><strong style="font-size: 18px">Verbale di esame Nr. {id} </strong></div>
        <div style="margin-top: 12px"><strong style="font-size: 11px">TIPOLOGIA DEL CORSO: {nome_corso}</strong></div>
        
        <p style="font-size: 11px">Il giorno {exam_day} alle ore {exam_hour} presso la struttura accreditata della {academy} con sede in {school_address}, si è riunita la Commissione Esaminatrice prevista dal Decreto Direttoriale {nr_decreto}, istitutivo del Corso {nome_corso_it} per procedere all'esame teorico e pratico da parte dei candidati che hanno frequentato il corso di {nome_corso} svolto dal giorno {start_day} al giorno {exam_day} per un totale complessivo di 29 ore autorizzato dal Comando Generale del Corpo delle Capitanerie di Porto con Decreto {decretee}. Le lezioni teorico-pratiche sono state tenute da istruttori inclusi nel corpo docente riconosciuto ed accettato dal Comando Generale del Corpo delle Capitanerie di Porto; i loro nominativi risultano dal «‹Registro presenze allievi e docenti _Diario delle lezioni>>.</p>
        
        <strong style="font-size: 11px">La commissione è stata così formata: </strong><br/>
        <strong style="font-size: 11px">Uff.le della Capitaneria di Porto: &nbsp; &nbsp; &nbsp; <span style="margin-left: 48px">{autorita_marittima}</span></strong>
        <p style="font-size: 11px; margin-top: 0;margin-bottom: 0">Direttore del Corso: &nbsp; &nbsp; &nbsp;<span style="margin-left: 122px">{direttore}</span></p>
        <p style="font-size: 11px; margin-top: 0;">Docente: &nbsp; &nbsp; &nbsp;<span style="margin-left: 173px">{docente}</span></p>
        
        <span style="font-size: 11px">La commissione tenuto conto di quanto stabilito nella citata autorizzazione, provvede alla valutazione dei candidati che hanno seguito il corso, dopo aver fissato le modalità di svolgimento dell'esame e il criterio di valutazione delle prove effettuate dai candidati, così come nel seguito riportato:</span><br/>
        <strong style="font-size: 13px">MODALITA' D'ESAME</strong><br/>
        <strong style="font-size: 11px">CONOSCENZE:</strong><br />
        <span style="font-size: 11px">Le conoscenze teoriche ed il loro livello di apprendimento vengono rilevate a mezzo somministrazione contemporanea a tutti i candidati di un test a scelta multipla composto da 30 domande a risposta multipla, con cinque differenti ipotesi di risposta d asvolgere nel tempo massimo di 60 minuti. Il test, viene generato alla presenza del Presidente di Commissione mediante un software in dotazione al Centro di Formazione che seleziona dal database i singoli quesiti in formato excel, ed il relativo correttore per il controllo del test. Ciascun questionario è generato secondo un criterio di casualità che garantisce la differenzazione delle singole schede d'esame.</span><br/>    
        <strong style="font-size: 11px">COMPETENZE:</strong><br/>
        <span style="font-size: 11px">Le competenze vengono rilevate assegnato a ciascun candidato compiti e/o esercizi riguardanti gli aspetti pratici trattati nelle esercitazioni svolte durante il corso, così come riportato sulla scheda prova/pratica appositamente prevista.</span><br />
        <strong style="font-size: 11px; text-decoration: underline">CRITERIO DI VALUTAZIONE:</strong><br/>
        <span style="font-size: 11px">La commissione stabilisce il seguente criterio di valutazione:<br/>
        Ad ogni risposta esatta è assegnato un punto e la prova si intende superata se si raggiunge il punteggio minimo di 21 (21/30); non sono ammesse correzioni "risulterà idoneo il candidato che avrà riportato la sufficienza sia nella verifica delle conoscenze che delle competenze". A ciascun candidato risultato idoneo la Commissione rilascia l'attestato di idoneità e copia di esso da consegnare al compartimento marittimo di appartenenza per la trascrizione nei documenti matricolari.</span>
        <br />
        <div style="margin-top: 150px; display: ruby-text">
            <div style="width: 200px; font-size: 11px">
                <strong>IL DIRETTORE DEL CORSO</strong><br/>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{direttore}</i>
            </div>
            <div style="width: 200px; font-size: 11px">
                <strong>IL COMMISSARIO/SEGRETARIO</strong><br/>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{docente}</i>
            </div>
            <!--<div style="width: 200px; text-align: center; font-size: 11px; padding-left: 12px;">
                <strong>IL RAPPRESENTATE DELL'AUTORITA' MARITTIMA</strong><br/>
                <i>Signature of the holder of the statement</i>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{autorita_marittima}</i>

            </div>-->
        </div>
               <p style="margin: 0 auto; padding-top: 40px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
        </div>
        {partecipants}
      
        </div>
             <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">
            <div style="width: 100%; display: flex; justify-content: center; margin: 0 auto; text-align:center">
                    <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
                </div>
                <p>Dopo aver espletato tutti i compiti previsti dalla normativa vigente e aver compilato il presente verbale, che consta di nr. <strong> 03 (TRE) </strong> pagine retro bianche con segno a penna barrante, lo stesso viene letto approvato e sottoscritto da tutta la sottoelencata commissione.<br>
                </p>

                <p>La seduta è tolta alle ore {end_exam}</p>
              <div style="margin-top: 150px; display: ruby-text">
                        <div style="width: 200px; font-size: 11px">
                            <strong>IL DIRETTORE DEL CORSO</strong><br/>
                            <div style="padding-top: 32px; border-bottom:1px solid"></div>
                            <i style="font-size: 8px; margin-top:5px; display:block;">{direttore}</i>
                        </div>
                        <div style="width: 200px; font-size: 11px">
                            <strong>IL COMMISSARIO/SEGRETARIO</strong><br/>
                            <div style="padding-top: 32px; border-bottom:1px solid"></div>
                            <i style="font-size: 8px; margin-top:5px; display:block;">{docente}</i>
                        </div>
                        <!--<div style="width: 200px; text-align: center; font-size: 11px; padding-left: 12px;">
                            <strong>IL RAPPRESENTATE DELL'AUTORITA' MARITTIMA</strong><br/>
                            <i>Signature of the holder of the statement</i>
                            <div style="padding-top: 32px; border-bottom:1px solid"></div>
                            <i style="font-size: 8px; margin-top:5px; display:block;">{autorita_marittima}</i>
            
                        </div>-->
                    </div>
                    <p style="margin: 0 auto; padding-top: 100px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
            </div>
        </div>
    </body>
    
</html>`

const singlePartecipant = (groupPartecipants, id_course, page) => ` 
  <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;" class="page html2pdf__page-break">
            <div style="width: 100%; display: flex; justify-content: center; margin: 0 auto; text-align:center">
                    <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
                </div>
                <div style="margin-top: 24px; text-align: center; padding-left: 12px; padding-right: 12px;"><strong style="font-size: 18px">Risultati degli esami</strong></div>
                <div style="padding-left: 32px; padding-right: 32px">
                    <table style="border:1px solid #000; width: 100%;border-collapse: collapse; font-size: 12">
                        ${groupPartecipants.map((partecipant, idx) => ` <tr style="border: 1px solid #000;">
                            <td style="border:1px solid #000; width: 150px">
                                <div style="border-bottom: 1px solid #000; width: 150px; text-align: center; padding: 10px">
                                    <span>N. Registr. Attestato</span>
                                </div>
                                <div style="width: 150px; padding: 5px">
                                    <span style="width: 80px; font-size: 10px">${((idx + 1) + (page * 5)).toString().padStart(3, '0')}/${id_course}</span>
                                </div>
                            </td>
                            <td>
                                <div style="width: 100%; border-bottom: 1px solid #000; padding-bottom: 3px">
                                    <span style="font-size: 9px">DATI ANAGRAFICI del personale marittimo partecipante al corso di: ${id_course.split('/')[1]}</span>
                                </div>
                                <div style="padding-top: 10px">
                                    <span style="font-size: 9px">Nel caso di personale marittimo iscritto nelle matricole della gente di mare: <br/>
                                    NOME COGNOME &nbsp; &nbsp; <strong>${partecipant.first_name} ${partecipant.last_name}</strong><br />
                                    Nato a: ${partecipant.birthplace} (${partecipant.birth_province}) il ${moment(partecipant.birthday).format('DD/MM/YYYY')}  Nazionalità: ${partecipant.nation}.<br />
                                    C.F: ${partecipant.fiscal_code}<br />
                                    Compartimento di iscr. Ravenna (RA) data di iscrizione nelle matricole della GM_29/05/2015 Matricola nr. 6716 cat. Prima
                                    </span>
                                </div>
                            </td>
                            <td style="border-left: 1px solid #000">
                                <div style="border-bottom: 1px solid #000">
                                    <strong>Giudizio</strong>
                                </div>
                                <div style="height: 80px;display: flex; align-items: center; text-align: center; justify-content: center">
                                    <p style="font-size: 10px; text-align: center">Idoneo</p>
                                </div>
                            </td>
                        </tr>`).join('')}
                       
                    </table>
                    
            </div>

                    <p style="margin: 0 auto; padding-top: 25px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
            </div>
            `
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

const savePdf = (payload) => {
    // const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    console.log(payload.school.logo);
    function groupArray(array, groupSize) {
        return array.reduce((acc, _, index) => {
          if (index % groupSize === 0) {
            acc.push(array.slice(index, index + groupSize));
          }
          return acc;
        }, []);
      }
    console.log('partecipants', payload.partecipant);
    html2pdf()
        .set({
            filename: 'verbale_esame.pdf',
            html2canvas: { useCORS: true, allowTaint: true },
            pagebreak: {mode: 'legacy'}
        })
        .from(Libretto.replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
        .replaceAll('{docente}', payload.teacher)
        .replaceAll('{direttore}', payload.director)
        .replaceAll('{nome_corso}', payload.nome_corso)
        .replaceAll('{nome_corso_it}',  payload.generalCourse.print_name_it.replaceAll('Corso', '').replaceAll('Addestramento', '').replaceAll('in materia di "', ''))
        .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.generalCourse.acronimo}/1`)
        .replaceAll('{exam_day}', `${moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').format('dddd, DD/MM/YYYY')}`)
        .replaceAll('{exam_hour}', `${payload.course.dataValues.ora_esame}`)
        .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
        .replaceAll('{partecipants}', groupArray(payload.partecipant, 5).map((partecipant, idx) => singlePartecipant(partecipant, payload.id ? payload.id : `2024/${payload.nome_corso}/1`, idx)).join('\n'))
        .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
        .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
        .replaceAll('{academy}', `${payload.school.name}`)
        .replaceAll('{school_name}', `${payload.school.name}`)
        .replaceAll('{legal_address}', payload.school.sede_legale)
        .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
        .replaceAll('{website}', payload.school.url)
        .replaceAll('{piva}', payload.school.piva)
        .replaceAll('{end_exam}', payload.course.dataValues.fine_esame)
        .replaceAll('{decretee}', `${payload.decree.decrete}`)
        .replaceAll('{nr_decreto}', `${payload.generalCourse.numero_decreto}`)
    ).toPdf().get('pdf').then(pdf => {
        window.open(pdf.output('bloburl'), '_blank');
    })

}
export { Libretto, savePdf }