import { LoadingButton } from "@mui/lab";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { AuthHelper } from "./views/controllers/AuthHelper";

const modalStyle = {
 position: 'absolute',
 top: '50%',
 left: '50%',
 transform: 'translate(-50%, -50%)',
 width: '85vw',
 bgcolor: 'background.paper',
 boxShadow: 24,
 overflow: 'scroll',
 maxHeight: '90vh',
 p: 3,
};


const CreateTicket = (props) => {
 const [ticket, setTicket] = useState({
   description: "",
   subject: ""
 });


 const [loading, setLoading] = useState(false);
 const sendRequest = async() => {
   setLoading(true);
   try{
     const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;


     const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/task`, {
       method: 'POST',
       body: JSON.stringify({...ticket, email: _user.filter(x => x.Name === 'email')[0].Value})
     });
     setLoading(false);
     alert("Ticket inviato con successo");
     props.setModalOpen(false);
   }catch(ex){
     alert("Errore server");
   }
 }
 return <Modal
 open={props.modalOpen}
 onClose={() => props.setModalOpen(false)}
 aria-labelledby="modal-modal-title"
 aria-describedby="modal-modal-description"
>
 <Box sx={modalStyle}>
     <Typography variant="h6" component="h2">
         Crea Ticket
     </Typography>
     <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
       
         <TextField
             margin="normal"
             required
             fullWidth
             id="id"
             label="Titolo richiesta"
             name="id"
             onChange={(e) => setTicket({...ticket, subject: e.target.value})}
         />
         <TextField
             margin="normal"
             required
             fullWidth
             multiline
             maxRows={4}
             label="Messaggio"
             name="id"
             onChange={(e) => setTicket({...ticket, description: e.target.value})}
         />


         <LoadingButton variant="contained" onClick={() => sendRequest()} loading={loading}>Carica</LoadingButton>


     </Box>
 </Box>
</Modal>
 };


 export {CreateTicket}
