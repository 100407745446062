import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (row) =>`
        <tr>
            <td style="border: 1px solid black; padding: 5px;">${moment(row.date).format('dddd')}</td>
            <td style="border: 1px solid black; padding: 5px;">${moment(row.date).format('DD/MM/YYYY')}</td>
            <td style="border: 1px solid black; padding: 5px;">${row.hour_ranges?.split('-')[0]}</td>
            <td style="border: 1px solid black; padding: 5px;">${row.hour_ranges?.split('-')[1]}</td>
            <td style="border: 1px solid black; padding: 5px;">${moment(row.hour_ranges?.split('-')[1], 'HH:mm').diff(moment(row.hour_ranges?.split('-')[0], 'HH:mm'), 'hours')}</td>
            <td style="border: 1px solid black; padding: 5px;">-</td>
            <td style="border: 1px solid black; padding: 5px;">-</td>
            <td style="border: 1px solid black; padding: 5px;">-</td>
            <td style="border: 1px solid black; padding: 5px;">${row.teacher}</td>
            <td style="border: 1px solid black; padding: 5px;">${row.room}</td>
            <td style="border: 1px solid black; padding: 5px;">-</td>
        </tr>
        `;
const SingleRowPartecipants = (partecipant) =>`
<tr style="border: 1px solid black; border-collapse:collapse">
    <td style="width: 100px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
    <td style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
    <td style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
    <td style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_place}</td>
    <td style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birth_province}</td>
    <td style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{birthday}</td>
    <td style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{matricola}/{compartment}</td>
</tr>
`
const Libretto = `<html>
    <body style="font-family: Arial, Helvetica, sans-serif; font-size: 14px;width: 800px;margin-top: 0;">
        <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">    
        <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
            <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
        </div>
         <div style="float:right; width:100%;text-align: right">
         <strong>{intestazione_capitaneria}
         </strong></div>
       <br />
       <br />
        <br />
       <br />
        <div style="width: 100%; margin-top: 35px;"><strong>Oggetto: Lettera circolare prot. 0058744 del 16/06/2010 - Programmazione provvisoria del Corso {nome_corso} - {id}</strong>
        <br />
       <br />
        <br />
       <br />
       <strong>Programmazione del corso {nome_corso} - {id}</strong>

        <p>In attuazione di quanto previsto dalla circolare citata all'oggetto si comunicano le seguenti informazioni relative all'attivazione del Corso <strong></strong> che si svolgerà dal <strong>{from}</strong> al <strong>{to}</strong>
        <p style="margin: 0 auto; padding-top: 600px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>


        </div>
        </div>
        <div style="border:1px solid transparent; margin-top: 50px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">    
        <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
            <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
        </div>
         <table style="width: 100%; margin-top: 15px; border-collapse: collapse; border: 1px solid black;">
  <thead>
    <tr>
      <th rowspan="3" style="border: 1px solid black; padding: 5px;">Giorno</th>
      <th rowspan="3" style="border: 1px solid black; padding: 5px;">Data</th>
      <th colspan="6" style="border: 1px solid black; padding: 5px;">Ore di lezione</th>
      <th rowspan="3" style="border: 1px solid black; padding: 5px;">Nominativo istruttore</th>
      <th colspan="2" rowspan="2" style="border: 1px solid black; padding: 5px;">Strutture utilizzate</th>
    </tr>
    <tr>
      <th colspan="3" style="border: 1px solid black; padding: 5px;">Ore Teoria</th>
      <th colspan="3" style="border: 1px solid black; padding: 5px;">Ore Pratica</th>
    </tr>
    <tr>
      <th style="border: 1px solid black; padding: 5px;">Dalle</th>
      <th style="border: 1px solid black; padding: 5px;">Alle</th>
      <th style="border: 1px solid black; padding: 5px;">Tot</th>
      <th style="border: 1px solid black; padding: 5px;">Dalle</th>
      <th style="border: 1px solid black; padding: 5px;">Alle</th>
      <th style="border: 1px solid black; padding: 5px;">Tot</th>
      <th style="border: 1px solid black; padding: 5px;">Aule e laboratori</th>
      <th style="border: 1px solid black; padding: 5px;">Piscine e campo prove</th>
    </tr>
  </thead>
  <tbody>
    {rows_lessons}
  </tbody>
</table>
       
        <p style="margin: 0 auto; padding-top: 500px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
        </div>
         <div style="border:1px solid transparent; margin-top: 50px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">    
        <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
            <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
        </div>
       <table style="width: 100%; border: 1px solid black; border-collapse:collapse; font-size: 10px; font-weight: normal; text-align:left">
            <thead style="border: 1px solid black; border-collapse:collapse">
                <tr style="border: 1px solid black; border-collapse:collapse">
                    <th style="width: 100px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></th>
                    <th style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">COGNOME</th>
                    <th style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">NOME</th>
                    <th style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">Nato a</th>
                    <th style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">Prov.</th>
                    <th style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">Data di nascita</th>
                    <th style="width: 130px;padding:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">Matricola e Compartimento</th>
                </tr>
            </thead>
            <tbody style="border: 1px solid black; border-collapse:collapse">
               {partecipants}
            </tbody>
        </table>
        <br/>
        <br/>
        <br/>
        
        <strong>TOTALE CORSISTI {number_partecipants}</strong>
        <br/>
        <br/>
        <strong>Lo studio si riserva di integrare eventuali nuovi corsisti</strong>
        <br/>
        <strong>Con l'occasione Vi porgo cordiali saluti.</strong>
        <br/>
        <strong>Data ${moment().format('DD/MM/YYYY')}</strong>
       
        <p style="margin: 0 auto; padding-top: 500px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>
        </div>
    </body>
</html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'verbale_esame.pdf',
        html2canvas: { useCORS: true, allowTaint: true },
        pagebreak: {mode: 'legacy'}
    })
    .from(Libretto
        .replaceAll('{intestazione_capitaneria}', payload.settings.capitaneria.replaceAll('\\n', '<br/>'))
        .replaceAll('{docente}', payload.teacher)
        .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
        .replaceAll('{direttore}', payload.director)
        .replaceAll('{nome_corso}', payload.nome_corso)
        .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
        .replaceAll('{exam_day}', `${moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').format('dddd, DD/MM/YYYY')}`)
        .replaceAll('{school_name}', `${payload.school.name}`)
        .replaceAll('{legal_address}', payload.school.sede_legale)
        .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
        .replaceAll('{website}', payload.school.url)
        .replaceAll('{piva}', payload.school.piva)
        .replaceAll('{from}', moment(payload.course.dataValues.from).format('DD/MM/YYYY'))
        .replaceAll('{to}', moment(payload.course.dataValues.to).format('DD/MM/YYYY'))
        .replaceAll('{number_partecipants}', payload.partecipant.length)
        .replaceAll('{rows_lessons}', payload.lessons.map(x => SingleRow(x)))
        .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => SingleRowPartecipants(partecipant).replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
        .replaceAll('{docente}', payload.teacher)
        .replaceAll('{direttore}', payload.director)
        .replaceAll('{nome_corso}', payload.nome_corso)
        .replaceAll('{birth_place}', `${partecipant.birthplace}`)
        .replaceAll('{birth_province}', `${partecipant.birth_province}`)
        .replaceAll('{birthday}', `${moment(partecipant.birthday).format('DD/MM/YYYY')}`)
        .replaceAll('{matricola}', `${partecipant.matricola}`)
        .replaceAll('{compartment}', `${partecipant.compartimento_marittimo}`)
        .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
        .replaceAll('{expiry}', `${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('DD/MM/YYYY')}`)
        .replaceAll('{first_name}', `${partecipant.first_name}`)
        .replaceAll('{last_name}', `${partecipant.last_name}`)
        .replaceAll('{birth_place}', `${partecipant.birthplace}`)
        .replaceAll('{birth_province}', `${partecipant.birth_province}`)
        .replaceAll('{birth_province}', `${partecipant.birth_province}`)
        .replaceAll('{matricola}', `${partecipant.matricola}`)
        .replaceAll('{compartment}', `${partecipant.compartimento_marittimo}`)
        .replaceAll('{id_matricola}', `${(idx+1).toString().padStart(3, '0')}/${payload.id ? payload.id : `2024/${payload.nome_corso}/1`}`)
        .replaceAll('{fiscal_code}', `${partecipant.fiscal_code}`)
        .replaceAll('{decretee}', `${payload.decretee}`)
        .replaceAll('{birthday}', `${moment(partecipant.birthday).format('DD/MM/YYYY')}`)
        .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
        // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
        .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
        .replaceAll('{logo}', payload.school.logo)  ).join(''))
    ).toPdf().get('pdf').then(pdf => {
        window.open(pdf.output('bloburl'), '_blank');
    })
}
export { Libretto, savePdf }