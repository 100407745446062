import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const singleCourseTemplate = (row) => `<div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 30px; text-align: center">
<strong>Lezione nr. {no} </strong>
<table style="margin: 0 auto; padding-top: 14px; padding-bottom: 14px;">
    <tr>
        <td style="padding-right: 14px;padding-left: 14px;">Orari:<br/> <strong>${row.hour_ranges}</strong></td>
        <td style="padding-right: 14px;padding-left: 14px;">Data:<br/> <strong>${moment(row.date).format('DD/MM/YYYY')}</strong></td>
        <td style="padding-right: 14px;padding-left: 14px;">Aula:<br/> <strong>${row.room}</strong></td>    
    </tr>
</table>
<table>
<tr>
<td style="width: 50%; text-align: left">Insegnante: <strong> ${row.teacher} </strong></td>
<td style="width: 50%">
<table style="margin: 0 auto;padding-left: 150px;">
<tr><td>firma: </td><td><div style="width: 200px; border-bottom: 1px solid #000;padding-top: 18px;"></div></td></tr>
</table>
</td>
</tr>
</table>
<table border="0" style="margin: 0 auto; padding-top: 14px; border-collapse: collapse; width: 100%;">
<br/>
<tr>
        <td colspan="4" style="font-size: 14px; text-align: left;">
            Programma: <br />{description}<br/><br/><br/>
        </td>
    </tr>
    <tr style="padding-top: 48px">
        <td style="text-align: left">Didattica:   <strong>{theory}</strong></td>
        <td style="text-align: right">Totale Ore:  <strong>{hours}</strong></td>
    </tr>
  </table>
</div>`

const Practical = ` <div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 32px; text-align: center">
<table border="0"  style="width: 100%;border-collapse: collapse;margin: 0 auto; border: 1px solid #000; width: 100%;">
    <tr style="border-bottom: 1px solid #000">
      <td ><strong>Prova pratica</strong></td>
      <td ><strong>Effettuata</strong></td>
      <td ><strong>Non Effettuata</strong></td>
      <td ><strong>Firma istruttore</strong></td>
    </tr>
    <tr>
        <td style="font-size: 12px">{name}</td>
        <td style="height: 50px"></td>
        <td style="height: 50px"></td>
        <td style="height: 50px"></td>
    </tr>
  </table>
</div>`
const Libretto = `<html>
<head></head>
<body style="font-family: Arial, Helvetica, sans-serif">
    <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
        <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
    </div>
    {rows}
   
</body>
</html>`

const singleRow = `
<div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">
<div style="text-align:center; margin: 0 auto; width: 100%;">
    <h3>{course} - {id}</h3>
    <table style="width: 100%; text-align: center">
        <tr>
            <td>Data inizio corso: 07/10/2024</td>
            <td>-</td>
            <td>Data fine corso: 09/10/2024</td>
        </tr>
    </table>

    <h1 style="text-align: center; margin: 0 auto; width: 100%; padding-top: 80px;">
        Libretto Giornaliero del Corsista 
    </h1>
</div>
    
    <div style="padding-left: 32px; padding-right: 32px; margin-top: 32px">
        <div style="margin: 0 auto; text-align: center">
            <span><i>Corsista:&nbsp; </i><strong>{user}</strong></span><br />
            <span><i>Nato il:&nbsp;&nbsp;&nbsp;</i><strong>{birthday}</strong><br/> <i>a:</i> &nbsp;<strong>{birthplace}</strong></span><br />
            <br/><span><i>Matricola/Compartimento Marittimo:&nbsp;&nbsp;&nbsp;</i><strong>{matricola}/{compartment}</strong></span>
            

        </div>
    </div>
    <p style="margin: 0 auto;padding-top: 400px; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>

 </div>
 </div>
 <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">
    {lessonMap}
    
    {practical}
  
</div>
    `
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  const SinglePage = (lesson) => `
      <div style="border:1px solid transparent; margin-top: 20px; height: 750px; padding-left: 35px; padding-right: 35px;"  class="page html2pdf__page-break">    
          <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
              <img src="{logo}" crossorigin="*" style="height: 125px; object-fit: contain;"/>
          </div>
          <h3>{nome_corso} ({name_eng})</h3>
          <h3>{id}</h3>
          <strong>{row_number}° Giorno<strong>
          <strong>Data - {data} </strong>


            {single_lessons}
            <div style="margin-top: 50px"></div>
            <table style="margin-top:24px">
                <tr>
                <td>
                <div style="width: 200px; font-size: 11px">
                    <strong>IL DIRETTORE DEL CORSO</strong><br/>
                    <div style="padding-top: 32px; border-bottom:1px solid"></div>
                    <i style="font-size: 8px; margin-top:5px; display:block;">{direttore}</i>
                </div>
                </td>
                <td>
                <div style="width: 200px; font-size: 11px; margin-left: 140px">
                    <strong>Firma del corsista</strong><br/>
                    <div style="padding-top: 32px; border-bottom:1px solid"></div>
                </div>
                </td>
                </tr>
            </table>
          <p style="margin: 0 auto;padding-top: 10px; font-weight: normal; text-align: center; font-size: 10px">{school_name} SRL, {legal_address} - Tel. {phone} P.IVA: {piva} <br/> Sito web: {website}</p>

          </div>
      </div>`
const SingleLesson = (lesson, idx) => `<div style="margin-top: 50px;">
              <strong>${idx}° lezione</strong>
              <div style="max-width: 400px;">
                  <table style="width: 100%; border-collapse: collapse; font-weight: normal;">
                          <tr>
                              <td style="text-align: left; padding: 5px;">
                                  <p style="margin: 0;">Inizio</p>
                                  <span style="font-weight: bold;">${lesson.hour_ranges.split('-')[0]}</span>
                              </td>
                              <td style="text-align: left; padding: 5px;">
                                  <p style="margin: 0;">Fine</p>
                                  <span style="font-weight: bold;">${lesson.hour_ranges.split('-')[1]}</span>
                              </td>
                              <td style="text-align: left; padding: 5px;">
                                  <p style="margin: 0;">Totale ore</p>
                                  <span style="font-weight: bold;">{total_hour}</span>
                              </td>
                          </tr>
                      </table>
              </div>
              <table style="margin-top: 24px">
                  <tr>
                      <td style="font-weight: normal; width: 380px;">Insegnante: <strong>${lesson.teacher}</strong> <br/>
                       tipologia: <strong>${lesson.pratica ? 'Pratica' : 'Teorica'}</strong>
                      </td>
                      <td>
                          <table>
                              <tr>
                                  <td><p>firma</p></td>
                                  <td>
                              <div style="width: 250px; height: 18px; border-bottom:1px solid #000"></div></td>
                              </tr>
                              
                          </table>
                      </td>
                  </tr>
              </table>
              <div style="margin-top: 48px"></div>
              <strong style="padding-top: 48px;">Descrizione lezione:</strong><br/>
              <p style="font-weight: normal">${lesson.description}</p>
          </div>`;
const savePdf = async (payload) => {
    const mappa = Object.keys(payload.groupedByDate).map((day, idx) => 
        SinglePage(day).replaceAll('{docente}', payload.teacher).replaceAll('{row_number}', idx + 1)
        .replaceAll('{nome_corso}', payload.generalCourse['nome corso'])
        .replaceAll('{single_lessons}', payload.groupedByDate[day].map((lesson, idx) => SingleLesson(lesson, idx + 1)
            .replaceAll('{total_hour}', lesson.hours / 10)
        ).join(''))
        .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
        .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
        .replaceAll('{data}', moment(day).format('DD/MM/YYYY'))
        .replaceAll('{school_name}', `${payload.school.name}`)
        .replaceAll('{legal_address}', payload.school.sede_legale)
        .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
        .replaceAll('{website}', payload.school.url)
        .replaceAll('{direttore}', payload.director)
        .replaceAll('{piva}', payload.school.piva)).join('')
        .replaceAll('{name_eng}', payload.generalCourse.print_name_it)

    const practicalMap = payload.lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', payload.generalCourse['nome corso']).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', Number(x.hours)/10)).join('\n');
    const pdf = await html2pdf().set({
        filename: 'verbale_esame.pdf',
        html2canvas: { useCORS: true },
    })
        .from(Libretto
            
                .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)
                .replaceAll('{rows}', payload.partecipant.map((partecipant, idx) => singleRow.replaceAll('{course}', payload.generalCourse['nome corso']).replaceAll('{matricola}', `${partecipant.matricola}`)
                .replaceAll('{compartment}', `${partecipant.compartimento_marittimo}`)
                .replaceAll('{id}', payload.id)
                .replaceAll('{lessonMap}', mappa)
                .replaceAll('{user}', `${partecipant.first_name} ${partecipant.last_name}`)
                .replaceAll('{birthplace}', partecipant.birthplace)
                .replaceAll('{birthday}', moment(partecipant.birthday).format('DD/MM/YYYY'))
                .replaceAll('{practical}', practicalMap)))
                .replaceAll('{school_name}', `${payload.school.name}`)
                .replaceAll('{legal_address}', payload.school.sede_legale)
                .replaceAll('{phone}', `+39 ${payload.school.telefono}`)
                .replaceAll('{website}', payload.school.url)
                .replaceAll('{piva}', payload.school.piva)
                .replace('{docente}', payload.lessons[0].teacher)
                .replaceAll('{direttore}', payload.director)
            ).toPdf().get('pdf')

    window.open(pdf.output('bloburl'), '_blank');
}
export { Libretto, savePdf }