import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Button, Checkbox, Container, IconButton, Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import GzipHelper from "../../controllers/GzipHelper";
import { AuthHelper } from "../../controllers/AuthHelper";
import { register } from 'swiper/element/bundle';
import { Delete } from "@mui/icons-material";

register();

const ListTeachers = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [courses, setCourses] = useState([]);
    const elements = useSelector(state => state.elements);

    const teachers = useState({});

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Nome e cognome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'qualifica', headerName: 'Qualifica', width: 200, headerClassName: 'data-grid-header' },
       
    ];

    const fetchCertificates = async() => {
        const res = await(await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/certificates`)).json();
        setCourses([...res]);
    }
    const getCertificates = async() => {
        const apiKey = localStorage.getItem('cruddy-apiKey')
        const subCurrent = (await AuthHelper.getUserFromIdToken(apiKey)).Username;
        const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/direttori/${subCurrent}`)).json();
        setCertificates([...res]);
        setLoading(false);
    }
    useEffect(() => {
        getCertificates();
        fetchCertificates();
        store.dispatch(clear())
        builder('certificates.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('direttori.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica Docente", data.row);

    }

    const assignCourseToTeacher = async (course, teacher) => {
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/directors/assign/${teacher}`,
        {method: 'PUT', body: JSON.stringify({
            course, teachers
        })});
        
        await getCertificates();
    }
    const SingleCourse = (props) => {
        return (
            <div style={{maxWidth: 400, minHeight: 800,  paddingLeft: 120, paddingRight: 120, paddingBottom: 48}}>
                <h2 style={{textAlign: 'left'}}>{props.course['nome corso']}</h2>
                <div>
                    {certificates.map(teacher => (
                        <div style={{display: 'flex'}}><Checkbox checked={certificates.filter(x => x['id'] === teacher.id)[0].assignments.filter(x => x.course === props.course['id']).length > 0} onChange={() => assignCourseToTeacher(props.course['id'], teacher.id)} /><h5>{teacher.name}</h5>
                         <IconButton onClick={async () => {
                            try{
                                const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/directors/${teacher.id}`, {
                                    method: 'DELETE'
                                });
                                getCertificates();
                            }catch(ex){
                                alert("Errore server");
                            }
                            
                        }} ><Delete color="error"/></IconButton>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    return (<>
    
    {loading && <ListSkeleton />}
    {!loading && <swiper-container
    slides-per-view="3"
    navigation="true"
    pagination="true"
    style={{paddingBottom: 320}}
    >
        {courses.map(x => {
        console.log(x);    
        return(
            <swiper-slide><SingleCourse course={x} /></swiper-slide>
        )})}
    </swiper-container> }
    <FloatingActionButtons onClick={() => handleClick([], "Crea Direttore")}/>
    </>);
}

export default ListTeachers;